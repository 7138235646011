<template>
  <div
    class="_w-max _pc-mar-y-45 _mb-mar-y-60 _pc-pad-x-200 _mb-pad-x-30 _flex-col-center wow fadeInUp"
  >
    <div>
      <!--title标题-->
      <div class="_pc-mar-bottom-35 _mb-mar-bottom-35 _flex-row-end">
        <div
          class="_pc-font-42 _mb-font-58"
          style="
            color: var(--theme-color-title);
            font-weight: 800;
            line-height: 0.9;
          "
        >
          ABOUT US
        </div>
        <div
          class="_pc-font-28 _pc-pad-left-20 _mb-font-32 _mb-mar-left-25"
          style="font-weight: 800"
        >
          {{ about.catename }}
        </div>
      </div>
    </div>

    <div class="_flex-row-mid _mb-display-block">
      <div
        class="_pc-mar-right-50 _pc-w-690 _mb-w-max _mb-mar-bottom-40"
        style="color: var(--theme-color-title)"
      >
        <div class="_w-max">
          <div
            class="_pc-font-19 _mb-font-28 _mb-mar-bottom-25"
            style="font-weight: 800; color: #eb642b"
          >
            · 企业介绍
          </div>
          <div
            class="_pc-font-16 _mb-font-24 _pc-mar-y-20 _mb-mar-bottom-30"
            style="color: #000; line-height: 1.9;font-family:'Microsoft YaHei';"
            v-html="about.content"
          ></div>
          <P
            class="_pc-font-19 _mb-font-28 _mb-mar-bottom-25"
            style="font-weight: 800; color: #eb642b"
            >· 我们的优势</P
          >
          <div
            class="_pc-font-16 _flex-row-bet-mid _pc-pad-x-20 _mb-font-22"
            style="color: #000"
          >
            <div
              v-for="(item, index) in data"
              class="_flex-col-mid about-us-content"
              v-show="item.is_banner != 1"
              :key="index"
            >
              <img
                class="_pc-w-60 _mb-w-60 _pc-mar-y-15 _mb-mar-y-15"
                :src="item.image_input"
                alt=""
              />
              <span
                class="_pc-pad-top-10 _mb-pad-top-5 _pc-font-16"
                style="font-family:'Microsoft YaHei';"
                v-html="item.content"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="_pc-mar-x-50 _pc-w-690 _flex-row _mb-w-max _mb-mar-x-0"
        style=""
      >
        <img
          v-for="(item, index) in about.image_input"
          class="_pc-w-690 _mb-w-max"
          style="height: auto"
          :src="item"
          alt=""
          v-show="index == 0"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      about: [],
      //我们的优势下的四个图标
      data: {},
    };
  },
  mounted() {
    this.get_data();
  },
  created() {},
  methods: {
    async get_data() {
      let data = {
        page: 1,
        limit: 5,
        cid: 29,
      };
      await this.$axios
        .get("site/get_site_newList", { params: data })
        .then((res) => {
          for (let i = 0; i < res.data.data.all.length; i++) {
            if (res.data.data.all[i].is_banner == 1) {
              this.about = res.data.data.all[i];
            }
          }
          this.data = res.data.data.all;
          // console.log(res)
        });
    },
  },
};
</script>

<style scoped>
/deep/ .about-us-content font {
  font-size: 0.8vw;
}
@media screen and (max-width: 768px) {
  /deep/ .about-us-content font {
    font-size: 2.5vw;
  }
}
</style>
